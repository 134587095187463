"use client";

import React, { useState, useEffect } from "react";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import Image from "next/image";
import { language } from "@/data/language";

const LanguageSwitcher = ({params}) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [selectedOption, setSelectedOption] = useState(() => {
    return language.find((lang) => lang.code === params.locale);
  });

  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);

  const handleLocaleChange = async (option) => {
    setSelectedOption(option);
    setIsOptionsExpanded(false);

    if (typeof window !== "undefined") {
      localStorage.setItem("selectedLanguage", option.code);
    }

    // Construct the new path with the selected locale
    const newPath = `/${option.code}${pathname.replace(/^\/(en|es|fr|pt)/, "")}`;

    // Preserve query parameters
    const queryString = searchParams.toString();
    await router.push(`${newPath}${queryString ? `?${queryString}` : ""}`);
  };

  return (
    <div className="d-flex justify-content-center bg-light">
      <div className="position-relative">
        <button
          className="btn header-btn-2 d-flex align-items-center p-2"
          onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
          onBlur={() => setIsOptionsExpanded(false)}
        >
          <Image
            src={selectedOption.flag}
            width={20}
            height={20}
            alt="flag-image"
            className="me-2"
          />
          {selectedOption.country}
          <svg
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={`h-4 w-4 transition-transform ${
              isOptionsExpanded ? "rotate-180" : ""
            }`}
            style={{
              transform: isOptionsExpanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.2s ease-in-out",
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <div
          className={`position-absolute w-100 bg-white shadow-lg rounded ${
            !isOptionsExpanded ? "d-none" : ""
          }`}
        >
          <ul className="list-group">
            {language.map((option, index) => (
              <li
                key={index}
                className="list-group-item d-flex align-items-center cursor-pointer"
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleLocaleChange(option);
                }}
              >
                <Image
                  src={option.flag}
                  width={20}
                  height={20}
                  alt={option.country}
                  className="me-2"
                />
                {option.country}
                {selectedOption.code === option.code && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="ms-auto"
                    style={{ width: "24px", height: "24px", color: "green" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
