import overViewImg from "@/../public/assets/images/thumb/overview-img-2.svg";
import overViewShape from "@/../public/assets/images/thumb/overview-shape-2.svg";
import lotteryIndImage from "@/../public/assets/images/thumb/lottery-ind.svg"
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import transaction from "@/../public/assets/images/counter/transactions-per-day.svg";
import gamePlayer from '@/../public/assets/images/counter/igaming-players.svg';
import juridiction from "@/../public/assets/images/counter/jurisdiction.svg";
import posTerminal from "@/../public/assets/images/counter/pos-terminals.svg";
import popupImage from "@/../public/assets/images/popupbanner/Popup (2).webp";

const HomeData = {
  homeLotteryIndustry: {
    str_transform_the: "STR_TRANSFORM_THE",
    str_lottery_industry: "STR_LOTTERY_INDUSTRY",
    str_50_years: "STR_50_YEARS",
    str_skilrock_cutting_edge: "STR_SKILROCK_CUTTING_EDGE",
    str_infinite_serves: "STR_INFINITE_SERVES",
    image: lotteryIndImage,
    imageAlt: "Collage of two gaming activities: On the left, a man and woman are playing a lotto game. On the right, a man is shown playing a slot game on a tablet.",
  },
  homeOmniChannel: {
    str_true: "STR_TRUE",
    str_omniChannel: "STR_OMNICHANNEL",
    str_plateform: "STR_PLATFORM",
    str_omniChannel_desc: "STR_OMNICHANNEL_DESC",
    str_knowMore: "STR_KNOW_MORE",
    imageShape: overViewShape,
    imageShapeAlt: "Plate form image is not Loading",
    imageOverView: overViewImg,
    imageOverViewAlt:
      "Multicolor Infinity symbol in background with 'INFINITI' written in foreground",
  },
  homeOurPresence: {
    str_our: "STR_OUR",
    str_presence: "STR_PRESENCE",
    str_presence_desc: "STR_OUR_PRESENCE_DESC",
    presenceVideo: ""

  },
  experience: {
    rightArrow: faLongArrowAltRight,
    transaction: transaction,
    gamePlayer: gamePlayer,
    juridiction: juridiction,
    posTerminal: posTerminal
  },
  modaldata: {
    startDate: '2024-11-21',
    endDate: '2024-12-3',
    icon: popupImage,
    link: "https://us06web.zoom.us/webinar/register/1217322686064/WN_6eKWUvHUQyeOoUpUaTodAw",
  }
};

export { HomeData };
