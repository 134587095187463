import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/public/assets/css/style.css");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/public/assets/css/module.css");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/public/assets/css/flaticon.css");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/public/assets/images/counter/igaming-players.svg");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/public/assets/images/counter/jurisdiction.svg");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/public/assets/images/counter/pos-terminals.svg");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/public/assets/images/counter/transactions-per-day.svg");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/public/assets/images/popupbanner/Popup (2).webp");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/public/assets/images/thumb/lottery-ind.svg");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/public/assets/images/thumb/overview-img-2.svg");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/public/assets/images/thumb/overview-shape-2.svg");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/BackToTop/BackToTop.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Banner/Banner.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Contact/StickyContactButton.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Footer/Footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Header/Header.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Home/Experience.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Home/LotteryIndustry.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Home/OmniChannel.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Home/Presence.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Home/SinglePlatform.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Home/Upcoming.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Intro/Intro.js");
;
import(/* webpackMode: "eager" */ "/Users/abhisheksharma/Documents/Workspace/skilrock-app/src/components/Modal/Modal.module.css");
