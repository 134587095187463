import image1 from "@/../public/assets/images/single-platform/paper-lottery-home-page.webp";
import image2 from "@/../public/assets/images/single-platform/scratch-lottery-solutions.svg";
import image3 from "@/../public/assets/images/single-platform/scan-n-play-home.svg";
import image4 from "@/../public/assets/images/single-platform/igaming-lottery-solutions.svg";
import image5 from "@/../public/assets/images/single-platform/retail-lottery-solutions.svg";
import image6 from "@/../public/assets/images/single-platform/sports-lottery-solutions.svg";
import image7 from "@/../public/assets/images/single-platform/turnkey.svg";
import image8 from "@/../public/assets/images/single-platform/homepage GRS.webp";
import image9 from "@/../public/assets/images/single-platform/game.webp";

const home_banner = {
  singlePlateform: "STR_SINGLE_PLATFORM",
  singleLotteryWorld: "STR_FOR_LOTTERY_WORLD",
  singlePLatformdesc: "STR_SINGLE_PLATFORM_DESC",
  faRightIconClass: "fas fa-long-arrow-alt-right",
  crouselClass1: "col-md-4 col-lg-4 col-sm-6",
  crouselClass2: "service-item",
  imageDivClass: "service-img",
  linkClass: "image",
  contentDivClass: "service-content",
  contentHeadingClass: "title",
  contentReadMore: "read-more",
  crouselArray: [
    {
      image: image1,
      titleContent: "STR_PAPER_LOTTERY",
      link: "paper-lottery-solutions",
      alt: "collection of multiple retail pos devices used in retail lotteries",
      image2: image2,
      titleContent2: "STR_SCRATCH",
      link2: "instant-lottery",
      alt2: "a mobile device with a scratch lottery game and 2 scratch lottery tickets around it",
      image3: image3,
      titleContent3: "STR_SCAN_PLAY",
      link3: "scan-n-play",
      alt3: "",
    },
    {
      image: image4,
      titleContent: "STR_DIGITAL",
      link: "digital",
      alt: "mobile phone with lottery games displayed inside showcasing digital lottery options",
      image2: image5,
      titleContent2: "STR_RETAIL",
      link2: "retail",
      alt2: "collection of multiple retail pos devices used in retail lotteries",

      image3: image6,
      titleContent3: "STR_SPORTS",
      link3: "sports-lottery",
      alt3: "banner for sports lottery solutions that shows mobile device with sports characters",
    },
    {
      image: image7,
      titleContent: "STR_TURNKEY_SERVICE",
      link: "turnkey-solutions",
      alt: "laptop with graphs displayed on-screen and gears next to it",
      image2: image8,
      titleContent2: "STR_LOTTERY_REGULATORY_SYSTEM",
      link2: "gaming-regulatory-system",
      alt2: "banner for sports lottery solutions that shows mobile device with sports characters",
      image3: image9,
      titleContent3: "STR_GAMES",
      link3: "igaming-games",
      alt3: "a casino slot machine, a wheel of fortune, and a cell phone showing a QR code to showcase cashless gaming",
    },
  ],
};
export { home_banner };
